/* global  */ // tUrl等のグローバル変数を宣言する
import autoScroll from './functions/_autoScroll'

import 'core-js/features/number/is-nan' // SwiperのIE対応
import 'custom-event-polyfill' // SwiperのIE対応
import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,

// TOP動画
if ($('.js-topMv')[0]) {
  // クッキー処理
  const cookieArray = []
  if (document.cookie !== '') {
    const tmp = document.cookie.split('; ')
    for (let i = 0; i < tmp.length; i++) {
      const data = tmp[i].split('=')
      cookieArray[data[0]] = decodeURIComponent(data[1])
    }
  }
  const topAnime = cookieArray['topAnime']
  if (!topAnime) {
    $('.js-topPanel').show()
    // クッキー処理
    const date = new Date()
    const todayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
    const dateTime = date.getTime()
    const todayEndTime = todayEnd.getTime()
    const remainingTime = Math.ceil((todayEndTime - dateTime) / 1000)
    document.cookie = 'topAnime=allready;max-age=' + remainingTime
  }

  // アニメーション
  let setoutTime = 0
  if ($('.js-topImg')[0]) {
    setoutTime = '4000'
  }
  const mv = $('.js-topMv').get(0)
  const playMv = function () {
    $('.js-topMv').removeClass('ended')
    mv.play()
  }
  $(window).on('load', playMv)
  // mv.on('ended', function () {
  //   console.log('ended')
  //   mv.addClass('ended')
  // })
  mv.onended = (event) => {
    $('.js-topMv').addClass('ended')
    // setTimeout(playMv, setoutTime)
  }
}

// TOP検索ボックス
$('.js-topMvSearch-close').on('click', function () {
  $(this).parent('.topMvSearch').fadeOut()
})
$('.js-topSearchSelect-status').on('change', function () {
  if ($(this).value !== '') {
    $('.js-topSearchSelect-area option[value=""').prop('selected', true)
  }
})
$('.js-topSearchSelect-area').on('change', function () {
  if ($(this).value !== '') {
    $('.js-topSearchSelect-status option[value=""').prop('selected', true)
  }
})
$('.js-topSearchSelect').on('click', function () {
  let param = ''
  const area = $('.js-topSearchSelect-area').val()
  const status = $('.js-topSearchSelect-status').val()
  if (area !== '') {
    param = '?area=' + area
  } else if (status !== '') {
    param === '' ? (param = '?facilitystatus=' + status) : (param = param + '&facilitystatus=' + status)
  }
  if (param !== '') {
    window.location.href = './property-portfolio/' + param + '#facility-list'
  }
})

// concept 動画
$('.js-conceptMv-mute').on('click', function () {
  const video = $('.js-conceptMv').get(0)
  const icon = $(this).children('.icon')
  if (video.muted) {
    video.muted = false
    icon.addClass('icon-on')
    icon.removeClass('icon-off')
  } else {
    video.muted = true
    icon.removeClass('icon-on')
    icon.addClass('icon-off')
  }
})

/*
        ##  ######          #### ##    ##
        ## ##                ##  ####  ##
        ##  ######  #######  ##  ## ## ##
  ##    ##       ##          ##  ##  ####
   ######   ######          #### ##    ##
*/
if (document.getElementsByClassName('js-in')[0]) {
  // 画面に入った監視対象に.animatedを付加
  // Intersection Observer
  intObs()
}
if (document.getElementsByClassName('js-in-slow')[0]) {
  // 画面に入った監視対象に.animatedを付加
  // Intersection Observer
  intObs('js-in-slow', '-20% 0px', '100px 0px')
}
function intObs(className = 'js-in', margin = '-10px 0px', spmargin = '100px 0px') {
  let rootMargin = margin
  if (window.matchMedia('(max-width:768px)').matches) {
    //SPは検出を早くする
    rootMargin = spmargin
  }
  const intObsOption = {
    // root: document.querySelector('#obsArea'), // 監視領域 省略でビューポート
    rootMargin: rootMargin, // 指定px,%分判定領域を増減する -50% 0pxで画面中央の走査線になる
    threshold: 0, // 発火判定の閾値 0.1=10%以上交差で発火 0で接触次第発火
  }
  const observer = new IntersectionObserver(intObsCallback, intObsOption)
  const intObsItems = document.getElementsByClassName(className)
  for (let index = 0; index < intObsItems.length; index++) {
    const intObsItem = intObsItems[index]
    observer.observe(intObsItem) // 監視対象に加える
  }
  function intObsCallback(entries) {
    for (let index = 0; index < entries.length; index++) {
      const entry = entries[index]
      if (entry.isIntersecting) {
        const entryItem = entry.target
        entryItem.classList.add('active')
        observer.unobserve(entryItem) // 監視対象から外す
      }
    }
  }
}

/*
  ##     ##  #######  ########     ###    ##
  #### #### ##     ## ##     ##  ##   ##  ##
  ## ### ## ##     ## ##     ## ##     ## ##
  ##     ## ##     ## ##     ## ######### ##
  ##     ##  #######  ########  ##     ## ########
*/
$(document).on('click', '.js-facilityModal-btn', function () {
  openModal($('.js-facilityModal--' + this.dataset.modal))
  return false
})

let scrollTop
let modalSlide
// モーダル開く
function openModal(el) {
  const elModal = el
  const elModalContent = elModal.children('.js-facilityModal')
  scrollTop = $(window).scrollTop()
  elModal.addClass('active')
  el.find('.js-facilityModal-slide').addClass('js-facilityModal-slide-active')
  // elModal.scrollTop(0)
  // $('body').addClass('modalOn')
  // $('body').css({ top: -1 * scrollTop })
  if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1) {
    for (let i = 0; i < elModalContent.length; i++) {
      elModalContent[i].addEventListener(
        'touchmove',
        function (e) {
          const scroll = elModalContent.scrollTop
          const range = elModalContent.scrollHeight - elModalContent.offsetHeight - 1
          if (scroll < 1) {
            e.preventDefault()
            elModalContent.scrollTop = 1
          } else if (scroll > range) {
            e.preventDefault()
            elModalContent.scrollTop = range
          }
        },
        { passive: false }
      )
    }
  }
  // スライド作成
  modalSlide = new Swiper('.js-facilityModal-slide-active', {
    speed: 1000,
    loop: true,
    navigation: {
      nextEl: '.js-facilityModal-slide-active .swiper-button-next',
      prevEl: '.js-facilityModal-slide-active .swiper-button-prev',
    },
    pagination: {
      el: '.js-facilityModal-slide-active .swiper-pagination',
      type: 'fraction',
    },
  })
}
// モーダル閉じる
$(document).on('click', '.js-facilityModal-close', function () {
  closeModal($(this).closest('.js-facilityModal'))
})
$(document).on('click', '.js-facilityModal', function () {
  closeModal($(this))
})
$(document).on('click', '.js-facilityModal-content', function (e) {
  e.stopPropagation()
})
function closeModal(el) {
  el.removeClass('active')
  el.find('.js-facilityModal-slide-active').removeClass('js-facilityModal-slide-active')
  // $('body').removeClass('modalOn')
  // $(window).scrollTop(scrollTop)
  // スライド除去
  modalSlide.destroy()
  modalSlide = undefined
}

$('.js-requestAreaMap').on('click', function () {
  if (window.matchMedia('(min-width:769px)').matches) {
    // PCのみ
    const el = $(this).next()
    openMapModal(el)
    return false
  }
})
// モーダル開く
function openMapModal(el) {
  let elModal = el
  let elModalContent = elModal
  scrollTop = $(window).scrollTop()
  elModal.addClass('active')
  elModal.scrollTop(0)
  if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1) {
    for (let i = 0; i < elModalContent.length; i++) {
      elModalContent[i].addEventListener(
        'touchmove',
        function (e) {
          const scroll = elModalContent.scrollTop
          const range = elModalContent.scrollHeight - elModalContent.offsetHeight - 1
          if (scroll < 1) {
            e.preventDefault()
            elModalContent.scrollTop = 1
          } else if (scroll > range) {
            e.preventDefault()
            elModalContent.scrollTop = range
          }
        },
        { passive: false }
      )
    }
  }
}
// モーダル閉じる
$('.js-requestAreaMap-close').on('click', function () {
  closeMapModal($(this).closest('.js-requestAreaMap-dtl'))
})
$('.js-requestAreaMap-dtl').on('click', function () {
  closeMapModal($(this))
})
$('.js-requestAreaMap-content').on('click', function (e) {
  e.stopPropagation()
})
function closeMapModal(el) {
  el.removeClass('active')
}

if ($('.js-property-portfolioDetailImage-slide')[0] && window.matchMedia('(min-width:769px)').matches) {
  const portfolioDetailImageSlide = new Swiper('.js-property-portfolioDetailImage-slide', {
    speed: 1000,
    loop: true,
    navigation: {
      nextEl: '.js-property-portfolioDetailImage-slide .swiper-button-next',
      prevEl: '.js-property-portfolioDetailImage-slide .swiper-button-prev',
    },
    pagination: {
      el: '.js-property-portfolioDetailImage-slide .swiper-pagination',
      clickable: true,
    },
  })
}
if ($('.js-property-portfolioDetailImageSP-slide')[0] && window.matchMedia('(max-width:768px)').matches) {
  const portfolioDetailImageSlide = new Swiper('.js-property-portfolioDetailImageSP-slide', {
    speed: 1000,
    loop: true,
    navigation: {
      nextEl: '.js-property-portfolioDetailImageSP-slide .swiper-button-next',
      prevEl: '.js-property-portfolioDetailImageSP-slide .swiper-button-prev',
    },
    pagination: {
      el: '.js-property-portfolioDetailImageSP-slide .swiper-pagination',
      clickable: true,
    },
  })
}

/*
   ###          ##    ###    ##     ##
 ##   ##        ##  ##   ##    ## ##
##     ##       ## ##     ##    ###
######### ##    ## #########   ## ##
##     ##  ######  ##     ## ##     ##
*/
let ajax = false // Ajaxリクエスト

/*
        ##       #### ##    ## ##    ##
        ##        ##  ####  ## ##  ##
####### ##        ##  ## ## ## #####
        ##        ##  ##  #### ##  ##
        ######## #### ##    ## ##    ##
*/
if (document.getElementsByClassName('js-linkAjax')[0]) {
  window.addEventListener('click', (e) => {
    if (e.target.classList.contains('js-linkAjax')) {
      e.preventDefault()
      const item = e.target
      const url = item.attributes['href'].value // 取得先
      const type = item.dataset.type // add(追加)もしくはrep(差し替え)
      const from = item.dataset.from // 取得要素
      const to = item.dataset.to // 差し替え要素
      const pushSt = item.dataset.push // URLのプッシュ
      const scroll = item.dataset.scroll // スクロール先
      const flag = item.dataset.flag // 追加の処理フラグ
      applyAjax(url, from, to, pushSt, type, scroll, flag)
    }
  })
}
/*
        ######## ######## ##     ## ########
           ##    ##         ## ##      ##
#######    ##    ######      ###       ##
           ##    ##         ## ##      ##
           ##    ######## ##     ##    ##
*/
let ajaxWord = ''
if (document.getElementsByClassName('js-textAjax')) {
  window.addEventListener('input', (e) => {
    if (e.target.classList.contains('js-textAjax')) {
      e.preventDefault()
      const item = e.target
      if (item.classList.contains('reset')) {
        item.classList.remove('reset')
      } else if (ajaxWord === item.value) {
        // キーワードが変わっていなければ処理しない
        return false
      }
      ajaxWord = item.value
      const url = item.dataset.base + '?s=' + encodeURI(ajaxWord) // 取得先
      const type = item.dataset.type // add(追加)もしくはrep(差し替え)
      const from = item.dataset.from // 取得要素
      const to = item.dataset.to // 差し替え要素
      const pushSt = item.dataset.push // URLのプッシュ
      const scroll = item.dataset.scroll // スクロール先
      applyAjax(url, from, to, pushSt, type, scroll)
    }
  })
}

/*
         ######  ######## ##       ########  ######  ########
        ##       ##       ##       ##       ##          ##
#######  ######  ######   ##       ######   ##          ##
              ## ##       ##       ##       ##          ##
         ######  ######## ######## ########  ######     ##
*/
if (document.getElementsByClassName('js-selectAjax')) {
  window.addEventListener('change', (e) => {
    if (e.target.classList.contains('js-selectAjax')) {
      e.preventDefault()
      const item = e.target
      const url = item.value // 取得先
      const type = item.dataset.type // add(追加)もしくはrep(差し替え)
      const from = item.dataset.from // 取得要素
      const to = item.dataset.to // 差し替え要素
      const pushSt = item.dataset.push // URLのプッシュ
      const scroll = item.dataset.scroll // スクロール先
      applyAjax(url, from, to, pushSt, type, scroll)
    }
  })
}

/*
        ########     ###     ######  ##    ##
        ##     ##  ##   ##  ##       ##  ##
####### ########  ##     ## ##       #####
        ##     ## ######### ##       ##  ##
        ########  ##     ##  ######  ##    ##
*/
const backAjax = document.getElementById('js-backAjax')
if (backAjax) {
  if (window.history && window.history.pushState) {
    window.onpopstate = function (e) {
      const item = backAjax
      const url = location.href // 取得先
      const from = item.dataset.from // 取得要素
      const to = item.dataset.to // 差し替え要素
      applyAjax(url, from, to, false, 'rep', false)
    }
  }
}

/*
        ########    ###     ######  #### ##       #### ######## ##    ##
        ##        ##   ##  ##        ##  ##        ##     ##      ####
####### ######   ##     ## ##        ##  ##        ##     ##       ##
        ##       ######### ##        ##  ##        ##     ##       ##
        ##       ##     ##  ######  #### ######## ####    ##       ##
*/
if (document.getElementsByClassName('js-facilityAjax')) {
  window.addEventListener('change', (e) => {
    let paramFacility = ''
    let i
    // エリアチェック時
    if (e.target.getAttribute('name') === 'facilitySearchArea') {
      const val = e.target.value
      // 条件のチェックを外す
      if (document.facilitySearch.facilitySearchStatus[0]) {
        for (i = 0; i < document.facilitySearch.facilitySearchStatus.length; i++) {
          if (document.facilitySearch.facilitySearchStatus[i].checked) {
            document.facilitySearch.facilitySearchStatus[i].checked = false
          }
        }
      } else {
        document.facilitySearch.facilitySearchStatus.checked = false
      }
      // パラメータ生成
      paramFacility = '?area=' + val
    }
    // 条件チェック時
    if (e.target.getAttribute('name') === 'facilitySearchStatus') {
      const val = e.target.value
      // エリアのチェックを外す
      if (document.facilitySearch.facilitySearchArea[0]) {
        for (i = 0; i < document.facilitySearch.facilitySearchArea.length; i++) {
          if (document.facilitySearch.facilitySearchArea[i].checked) {
            document.facilitySearch.facilitySearchArea[i].checked = false
          }
        }
      } else {
        document.facilitySearch.facilitySearchArea.checked = false
      }

      // パラメータ生成
      // すべてのチェックボックスから取得
      const statusCheck = document.querySelectorAll('input[name="facilitySearchStatus"]')
      for (const iterator of statusCheck) {
        if (!iterator.checked) {
          continue
        }

        if (~paramFacility.indexOf('?facilitystatus=')) {
          paramFacility += ','
        } else {
          paramFacility += '?facilitystatus='
        }
        paramFacility += iterator.value
      }
    }

    if (e.target.classList.contains('js-facilityAjax')) {
      e.preventDefault()
      const item = e.target
      const url = '../wp/facility/' + paramFacility // 取得先
      const type = 'rep' // add(追加)もしくはrep(差し替え)
      const from = '#facility-list' // 取得要素
      const to = '#facility-list' // 差し替え要素
      const pushSt = '' // URLのプッシュ
      const scroll = false // スクロール先
      applyAjax(url, from, to, pushSt, type, scroll)
    }
  })
}

/*
           ###    ########  ########  ##       ##    ##
         ##   ##  ##     ## ##     ## ##         ####
####### ##     ## ########  ########  ##          ##
        ######### ##        ##        ##          ##
        ##     ## ##        ##        ########    ##
*/
function applyAjax(url, from, to, pushSt, type = 'rep', scroll = false, flag = null) {
  from = from.split(',') // ,区切りで配列に変換
  to = to.split(',') // ,区切りで配列に変換

  // console.log(url)
  if (pushSt === 'on') {
    history.pushState(null, null, url) // URL変更
  }

  // Ajaxと明示するパラメータを付ける
  if (~url.indexOf('?')) {
    url += '&ajax=1'
  } else {
    url += '?ajax=1'
  }

  // ローディング処理
  for (let index = 0; index < to.length; index++) {
    const itemTo = document.querySelector(to[index])
    const loadingItem = document.createElement('div')
    loadingItem.className = 'md-loading active'
    if (type === 'rep') {
      loadingItem.style.width = itemTo.offsetWidth + 'px'
      loadingItem.style.height = itemTo.offsetHeight + 'px'
      // console.log(loadingItem)
      itemTo.innerHTML = ''
    }
    itemTo.appendChild(loadingItem)
  }

  if (ajax) {
    // 既に通信中なら切断
    ajax.abort()
  }

  ajax = new XMLHttpRequest() // Ajaxリクエスト
  ajax.open('GET', url, true) // GET/POST, URL, 非同期処理
  ajax.setRequestHeader('X-Requested-With', 'XMLHttpRequest') // Ajaxでのアクセスと明示するリクエストヘッダー
  // キャッシュせず最新のデータを取る場合のリクエストヘッダー3種
  // ajax.setRequestHeader('Pragma', 'no-cache')
  // ajax.setRequestHeader('Cache-Control', 'no-cache')
  // ajax.setRequestHeader('If-Modified-Since', 'Thu, 01 Jun 1970 00:00:00 GMT')
  ajax.responseType = 'document' // 取得タイプ
  ajax.timeout = 10000 // タイムアウト判定時間
  ajax.send() // リクエスト実行
  ajax.onload = function () {
    // アクセス成功
    if (ajax.status < 200 || ajax.status >= 400) {
      console.log('Error: Code ' + ajax.status) // エラー
      return
    }
    if (scroll) {
      autoScroll(scroll, 30, true)
    }

    for (let index = 0; index < to.length; index++) {
      const itemFrom = this.response.querySelector(from[index])
      const itemTo = document.querySelector(to[index])

      if (type === 'rep') {
        itemTo.innerHTML = itemFrom.innerHTML
      } else {
        // addなら既存の要素を消さずに追加
        const delLoading = itemTo.querySelector('.md-loading')
        delLoading.parentNode.removeChild(delLoading)
        itemTo.innerHTML += itemFrom.innerHTML
      }
    }
  }
  ajax.ontimeout = function (e) {
    // タイムアウト
    console.log('ajax timeout')
  }
  ajax.onerror = function () {
    // Ajax処理失敗
    console.log('ajax error')
  }
  ajax.onloadend = function () {
    // 受信成否に関わらず実行
    ajax = false
  }
}

/*
    ##     ##    ###    ########
    ###   ###   ## ##   ##     ##
    #### ####  ##   ##  ##     ##
    ## ### ## ##     ## ########
    ##     ## ######### ##
    ##     ## ##     ## ##
    ##     ## ##     ## ##
*/
$('.js-facilityMap').on('change', function () {
  let param = ''
  if ($(this).attr('name') === 'facilitySearchArea') {
    param = '?area=' + $(this).val()
  } else if ($(this).attr('name') === 'facilitySearchStatus') {
    param = '?facilitystatus=' + $(this).val()
  }
  location.href = '../property-portfolio/' + param
})
